import React from "react"
import Header from "./header"
import Footer from "./footer"
import ScrollToTopBtn from "./ScrollToTop"
import { ThemeProvider } from "styled-components"
import { GlobalStyle } from "../styles/GlobalStyle"

export default ({ children }) => {
  return (
    <ThemeProvider theme={GlobalStyle}>
      <GlobalStyle />
      <Header />
      <main className="container mx-auto bg-white dark:bg-blue-900">
        {children}
      </main>
      <Footer />
      <ScrollToTopBtn />
    </ThemeProvider>
  )
}

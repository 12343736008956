import React from "react"
import {Link} from "gatsby"
import { SocialIcon } from 'react-social-icons';
import Logo from '../images/logo/logo.svg'

function Footer() {
    return (
  
      <footer className="bg-white dark:bg-blue-900">	
          <div className="container mx-auto flex items-center py-8">
  
              <div className="w-full mx-auto flex flex-wrap items-center">
                  <div className="flex w-full md:w-1/3 justify-center md:justify-start text-white font-extrabold">
                      <Link className="text-gray-900 no-underline hover:text-gray-900 hover:no-underline" to="/">
                        <img src={Logo} alt="Lese Joker footer logo" />
                      </Link>
                  </div>
                  <div className="flex w-full md:w-1/3 justify-center mt-4 md:mt:0 text-center">
                  {'Copyright © '} Lese Joker {new Date().getFullYear()}
      {'.'}
                  </div>
                  <div className="flex w-full pt-2 content-center justify-between md:w-1/3 md:justify-end">
                      <ul className="list-reset flex justify-center flex-1 md:flex-none items-center uppercase font-joker">
                      {[
                        { title: "Impressum", route: "/impressum" },
                        { title: "Über Mich", route: "/ubermich" },
                        { title: "Kontakt", route: "/kontakt" },
                        ].map(navigationItem => (
                        <li className="list-none" key={navigationItem.title}>
                          <Link className="inline-block text-joker dark:text-white no-underline hover:text-gray-400 hover:underline py-2 px-3" activeClassName="active" to={navigationItem.route}>
                          {navigationItem.title}
                          </Link>
                        </li>
                        ))}
                      </ul>
                  </div>
                  <div className="flex justify-center w-full md:justify-end ">
                  <SocialIcon url="https://twitter.com/lesejoker" style={{ height: 35, width: 35 }} className="mr-3 icon-hover" />
                  <SocialIcon url="https://instagram.com/lesejoker" style={{ height: 35, width: 35 }} className="mr-3 icon-hover"/>
                  <SocialIcon url="https://pinterest.com/lesejoker" style={{ height: 35, width: 35 }} className="mr-3 icon-hover"/>
                  </div>
              </div>
          
          </div>
    </footer>
  
    );
  }
  
  export default Footer;
import { createGlobalStyle } from "styled-components"
export const GlobalStyle = createGlobalStyle`
body {
  background-color: var(--bg);
  color: var(--textNormal);

  &.dark {
    --bg: #2a4365;
    --textNormal: #fff;
  }

  &.light {
    --bg: #fff;
    --textNormal: #000;
  }
}
`

import React, { Component } from "react";

export default class ScrollToTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false
    };
  }

  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function(e) {
      scrollComponent.toggleVisibility();
    });
  }

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true
      });
    } else {
      this.setState({
        is_visible: false
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  render() {
    const { is_visible } = this.state;
    return (
      <div className="scroll-to-top hover:text-yellow-800">
        {is_visible && (
          <div role="button" tabIndex={0} onClick={() => this.scrollToTop()} onKeyDown={() => this.scrollToTop()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 455 455"
              height="50"
              width="50"
              className="fill-current dark:text-white"
            >
              <path d="M227.5 0C101.855 0 0 101.855 0 227.5S101.855 455 227.5 455 455 353.145 455 227.5 353.145 0 227.5 0zm106.911 276.772L227.5 170.209 120.589 276.772l-21.178-21.248L227.5 127.852l128.089 127.673-21.178 21.247z" />
            </svg>
          </div>
        )}
      </div>
    );
  }
}
import { Link } from "gatsby"
import React from "react"
import { useState } from "react";
import Darkmode from './darkMode'
import Logo from '../images/logo/logo.svg'



function Header() {
    const [isExpanded, toggleExpansion] = useState(false);
    
    return (
    <header className="bg-white dark:bg-blue-900">
      <div className="flex flex-wrap md:flex-no-wrap items-center justify-center max-w-4xl mx-auto p-4 md:p-8">
        <div className="flex items-center">
        <Link to="/">
        <img src={Logo} width="600" alt="Lese Joker Logo"/>
        </Link>
        </div>
        
        
      </div>
      
      <div className="flex flex-wrap md:flex-no-wrap items-center justify-center max-w-4xl mx-auto p-4 md:p-8">
        <button className="block md:hidden border border-black flex items-center px-3 py-2 rounded text-black" onClick={()=>
          toggleExpansion(!isExpanded)}
          >
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
    
        <ul className={`${ isExpanded ? `block` : `hidden` } md:flex flex-col md:flex-row md:items-center md:justify-center
          text-sm font-joker uppercase leading-relaxed tracking-widest w-full md:w-auto`}>
          {[
          { title: "Home", route: "/" },
          { title: "Blog", route: "/blog" },
          { title: "Rezensionen", route: "/rezension" },
          { title: "Neuerscheinungen", route: "/neuerscheinungen" },
          { title: "Über Mich", route: "/ubermich" },
          { title: "Kontakt", route: "/kontakt" },
          ].map(navigationItem => (
          <li className="mt-3 md:mt-0 md:ml-6 list-none" key={navigationItem.title}>
            <Link className="block hover:text-joker no-underline hover:bg-white" activeClassName="active" to={navigationItem.route}>
            {navigationItem.title}
            </Link>
          </li>
          ))}
        </ul>
        
        
        <div className="ml-4"><Darkmode/></div>
      </div>
    </header>
    );
    }
    
    export default Header;

  
import React, { useState, useEffect } from 'react'
import sun from '../images/sun.svg'
import moon from '../images/moon.svg'

const DarkMode = props => {
  let websiteTheme
  if (typeof window !== `undefined`) {
    websiteTheme = window.__theme
  }

  const [theme, setTheme] = useState(websiteTheme)

  useEffect(() => {
    setTheme(window.__theme)
    window.__onThemeChange = () => {
      setTheme(window.__theme)
    }
  }, [])

  const ThemeToggle = () => {
    window.__setPreferredTheme(websiteTheme === 'dark' ? 'light' : 'dark')
  }

  return (
        <button onClick={ThemeToggle}>
          {theme === 'dark' ? (
            <img className="h-8 w-8" src={sun} alt="Light mode" />
          ) : (
           <img className="h-8 w-8" src={moon} alt="Dark mode" />
          )}
        </button>
  )
}

export default DarkMode

